<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="2">
          <!-- :resetImage="resetImageInput" -->
          <app-image-input-file :createFeature="true" :imgHeight="170" :imgWidth="150"
            :placeHolder="$t('personal picture')" @fileValue="newUser.image = $event"></app-image-input-file>
          <!-- @fileRemoved="deleteStudentPhoto('study_sequence')" -->
        </v-col>
        <v-col md="10" class="d-flex flex-column align-space-between">
          <div>
            <v-row>
              <v-col md="4">
                <v-text-field outlined dense v-model="newUser.name" :label="$t('name')"></v-text-field>
              </v-col>
              <v-col md="4" v-if="userType == 'admin'">
                <v-text-field outlined dense v-model="newUser.username" :label="$t('user name')"></v-text-field>
              </v-col>
              <v-col md="4" v-if="userType == 'admin'">
                <v-text-field outlined dense v-model="newUser.password" :label="$t('password')"></v-text-field>
              </v-col>
              <v-col md="4" v-if="userType == 'parent'">
                <v-text-field outlined dense hide-details v-model="newUser.phone" :label="$t('phone number')"
                  type="number"></v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-autocomplete v-if="userType == 'parent'"   outlined 
            :label="$t('Add Child')"  :items="childrens" :loading="childrensLoading" color="#757575" item-text="name"
            item-value="id" v-model="newChilld"  @keypress="fetchRegRecordAutoComplete($event)"
            @input="inputChild" return-object  >
          </v-autocomplete>
          
            <v-col v-if="userType == 'parent'">
              <h2>{{ $t('child') }}</h2>

            </v-col>
            <!-- <v-col>
              <v-row v-if="(userType == 'parent') && (newUser.children != null)">
                <v-col md="1">{{ $t('id') }}</v-col>
                <v-col md="6">{{ $t('name') }}</v-col>
              </v-row>
            </v-col> -->
            <v-col v-if="userType == 'parent' " v-for="(el,ix) in newUser.children" :key="ix">
              <v-row>
                <v-col md="1" class="center">
                <h4>  {{ el.id }} </h4>
                </v-col>
                <v-col md="6" class="center">
                <h4>  {{ el.name }} </h4>
                </v-col>
                <v-col md="2">
                <v-icon @click="delChild(ix)" color="red">mdi-delete</v-icon>
              </v-col>
              </v-row>

            </v-col>
        </v-col>
        <v-col md="12">
          <v-btn depressed color="green" class="white--text" @click="addUser" :loading="submitBtnLoading">
          {{ $t('storage')}}</v-btn>
        </v-col>
        <v-container v-if="userType == 'admin'">
          <div v-if="loadingAbilities" class="table__spinnerWrapper">
            <app-spinner></app-spinner>
          </div>
          <v-row v-else>
            <v-col md="12">
              <v-radio-group :dark="$store.state.isDarkMode"  row v-model="radioGroup" @change="handleRadioCases">
                <v-radio :label="$t('powers')" value="abilities"></v-radio>
                <v-radio :label="$t('roles')" value="roles"></v-radio>
              </v-radio-group>
            </v-col>
            <template v-if="radioGroup == 'abilities'">
              <v-col md="4" v-for="(value, key, idx) of abilities" :key="idx">
                <v-card class="add-user__chips-wrapper_Up">
                  <v-card-title class="justify-center">
                    <h2>{{ key }}</h2>
                  </v-card-title>
                  <v-card-text class="d-flex flex-column justify-center align-center">
                    <div v-for="(ability, idx) in abilities[key]" :key="idx + 1" class="add-user__chip_Up">
                      <v-switch color="green" @change="handleSwitchValue($event, ability)"></v-switch>
                      {{ ability.name }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
            <v-col md="6" v-if="radioGroup == 'roles'">
              <v-autocomplete :label="$t('roles')" outlined dense hide-details :items="roles" item-text="name"
                item-value="id" v-model="newUser.roleId"></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import imageInputFile from "../../../../components/base/imageInputFile.vue";
import { mapMutations } from "vuex";
import axios from "axios";
import debounce from "../../../../helpers/debounce";

export default {
  components: {
    appImageInputFile: imageInputFile,
  },
  data() {
    return {
      newChilld: null,
      radioGroup: "roles",
      submitBtnLoading: false,
      childrensLoading: false,
      childrens: [],
      loadingAbilities: false,
      // either admin or parent
      userType: this.$route.params.userType,
      newUser: {
        name: null,
        username: null,
        password: null,
        image: null,
        children: [],
        roleId: null,
        password: null,
      },
      abilities: {},
      toBeSubmittedAbilitites: {},
    };
  },
  methods: {
    delChild(i){
      this.newUser.children.splice(i,1);
    },
    handleRadioCases(radioValue) {
      if (radioValue == "roles") {
        this.toBeSubmittedAbilitites = {};
      } else {
        this.newUser.roleId = null;
      }
    
    },
    handleSwitchValue(switchVal, ability) {
      
      let abilityId = ability.id;
      if (switchVal) {
        this.toBeSubmittedAbilitites[abilityId] = true;
      } else {
        delete this.toBeSubmittedAbilitites[abilityId];
      }
     
    },
    async addUser() {
   
      try {
        this.submitBtnLoading = true;
        let endPoint = "";
        let requestBody = {};
        if (this.newUser.image) {
          requestBody.image = this.newUser.image;
        }
        requestBody.name = this.newUser.name;
        requestBody.username = this.newUser.username;
        if (this.userType == "parent") {
          endPoint = "/parent";
          requestBody.phone = this.newUser.phone;
          requestBody.children = this.newUser.children;
        }
        if (this.userType == "admin") {
          requestBody.password = this.newUser.password;
          endPoint = "/user";
          if (Object.keys(this.toBeSubmittedAbilitites).length != 0) {
            requestBody.abilities = Object.keys(this.toBeSubmittedAbilitites);
          }
          if (this.newUser.roleId) {
            requestBody.role_id = this.newUser.roleId;
          }
        }
        let form = new FormData();
        for (let key in requestBody) {
          if (key == "abilities") {
            requestBody[key].forEach((abilityId, index) => {
              form.append(`ability[${index}]`, abilityId);
            });
            continue;
          }
          if (key == "children") {
            requestBody[key].forEach((childId, index) => {
              form.append(`children[${index}]`, childId.id);
            });
            continue;
          }
          if (requestBody[key]) {
            form.append(`${key}`, requestBody[key]);
          }
        }
        await axios.post(endPoint, form);
        this.newUser = {
          name: null,
          username: null,
          password: null,
          image: null,
          children: [],
          roleId: null,
          password: null,
        },
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 1000 },
            "success"
          );
        this.$emit("submitted");
        if (this.userType == "admin") {
          this.$router.push("/admins");
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
    inputChild(){
      this.newUser.children.push(this.newChilld);
      this.newChilld = null;
      this.childrens = null;
    },
    async fetchRegRecordAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: searchValue,
              type: "parent",
            },
          });
          this.childrens = searchResult.data.data;
          
        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
    ...mapMutations({
      setRouteAddition: "setRouteNameAddition",
    }),
  },
  async created() {
    
    if (this.userType == "admin") {
      try {
        this.loadingAbilities = true;
        const abilities = await axios.get("/ability");
        const roles = await axios.get("/role", {
          params: {
            size: 10000,
          },
        });
        this.roles = roles.data.data;
        this.abilities = abilities.data;
      } catch (err) {
      } finally {
        this.loadingAbilities = false;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setRouteAddition(" ");
    next();
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to.params.userType == "parent") this.setRouteAddition("أب");
        if (to.params.userType == "admin") this.setRouteAddition("مسؤول");
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add-user__chips-wrapper_Up {
  /* max-height: 500px; */
  height: 470px;
  overflow: scroll;
  overflow-x: hidden;
  border: 1px solid #757575;
}

.add-user__chip_Up {
  border: 1px solid #757575;
  border-radius: 10px;
  margin: 5px 0;
  /* padding: 5px 0; */
  width: 100%;
  /* height: 70%; */
  color: var(--bg-color--font);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>